(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : "";
  j.async = true;
  // Use the Trusted Types policy
  j.src = trustedTypes
    .createPolicy("default", {
      createScriptURL: (url) => {
        return DOMPurify.sanitize(url, {RETURN_TRUSTED_TYPE: true});
      },
    })
    .createScriptURL("https://www.googletagmanager.com/gtm.js?id=" + i + dl);
  f.parentNode.insertBefore(j, f);
})(window, document, "script", "dataLayer", "GTM-WF3S4FLM");
